import store from '@/store'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

const noLoadingUrls = [
  /endpoints\/storages\/\d*\/import/,
  /endpoints\/storages\/\d*\/import_report/
]

const client = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  withCredentials: true,
  timeout: 1000 * 60 * 5
})

let self = {}
client.bindTo = (context) => { self = context }

client.interceptors.request.use((config) => {
  if (config.url.startsWith('~')) {
    config.url = config.url.slice(1)
  } else if (!noLoadingUrls.some(url => url.test(config.url))) {
    store.commit('Loading', true)
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      location: location.href,
      'X-Request-Id': uuid(),
      'endpoint-uid': self.endpointUid
    }
  }
})

client.interceptors.response.use(
  (response) => {
    store.commit('Loading', false)
    store.commit('cacheMap', response.headers['cache-map'])
    return response.data
  },
  (error) => {
    store.commit('Loading', false)
    if (error.response.status === 403) {
      store.dispatch('Logout', /auth$/.test(error.response.config?.url))
    } else {
      let msg = error.response.data?.message || error.response.data
      const operationName = error.response.headers['operation-name']
      if (operationName) msg = decodeURIComponent(operationName) + '\n' + msg
      store.commit('toast', [msg, 'error'])
    }
    throw new Error(`Axios: ${error.message}. ${error.response.data}`)
  }
)

export default client
